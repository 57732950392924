// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.

$body-padding-top: 0px;
$body-padding-left: 0px;
$navbar-bg: $white;
$navbar-dropdown-bg: $light;
$navbar-link-color: $gray-500;
$navbar-link-hover-color: $primary;
$header-height: 100px;