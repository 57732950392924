
.page-title {
    color: $black;
    font-weight: 600;
    font-size: calc(2rem + 2vmin);
}

.lead {
    font-size: 1.1rem !important;
}

.section-title {
    color: $black;
    font-weight: 600;
    font-size: calc(1.5rem + 1.5vmin);
}

.small-title {
    color: $black;
    font-weight: 600;
    font-size: calc(1.2rem + 1vmin);    
}

.subtitle {
    color: $primary;
    text-transform: uppercase;
    font-size: 17px !important;
    margin-bottom: 0.5rem;
    letter-spacing: 2px;
}

h1, h2, h3 {
    word-wrap: break-word;
    strong {
        color: $primary !important;
    }
}

.text-bold {
    font-weight: 600 !important;
}

.flaticon {
    font-size: 110px;
    line-height: 0 !important;
    display: block;
    height: 110px;
}

.flaticon-small {
    font-size: 60px;
    line-height: 0 !important;
    display: block;
    height: 60px;
}

.fa-20 {
    font-size: 20px !important;
}

.text-shadow-white {
    text-shadow: 0px 2px 8px $white;
}

/* blockquote */
blockquote {
    border-left: 0px !important;
    padding: 60px 40px 40px 40px;    
    margin: 0 0 4rem;
    position: relative;
	background-color: $white;
    @include shadow;
    border-radius: $border-radius-lg;
}
@media(max-width: 559px){
	blockquote {
		padding: 60px 20px 40px 20px;
	}
}
blockquote:before {
	content: '';
	position: absolute;
	top: -30px;
	width: 60px;
	height: 60px;
	background: $primary url(../images/quote.png) no-repeat center;
	z-index: 1;
}
blockquote p {
    font-style: italic;
	color: $gray !important;
}
blockquote cite {
	font-size: 15px !important;
	font-weight: 600 !important;
	font-style: normal !important;
    line-height: 1.1 !important;
    color: $black;
}