// body 
.btn {
    &-primary {
        background-color: $primary !important;
        color: $white;
        &:hover {
            color: $white;
        }
    }
    &-yellow {
        background-color: $yellow !important;
        color: $white;
        &:hover {
            color: $white;
        }
    }
    &-red {
        background-color: $red !important;
        color: $white;
        &:hover {
            color: $white;
        }
    }
    &-orange {
        background-color: $orange !important;
        color: $white;
        &:hover {
            color: $white;
        }
    }
    &-blue {
        background:#33B0A8;
        color: $white;
        &:hover {
            color: $white;
        }
    }
}

.row.no-margin {
    margin-left: 0px;
    margin-right: 0px;
}

.no-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.container-fluid {
    @include media-breakpoint-up(xl){
        max-width: 1600px;
    }
}

strong, b {
    font-weight: 600;
}

p {
    margin-bottom: 2rem;
}

a:hover {
    text-decoration: none !important;
}

.shadow {
    @include shadow;
}

.background-cover {
    @include background-cover;
}

.background-fixed {
    @include background-fixed;
}

.section-p-5 {
    padding: calc(2rem + 5vmin) 0;
}

.section-p-10 {
    padding: calc(2rem + 10vmin) 0;
}

.position-relative {
    position: relative;
    z-index: 2;
}

.z-index-100 {
    position: relative;
    z-index: 100;
}

.position-pull-right {
    position: relative;
    z-index: 10;    
    @include media-breakpoint-up(xl){
        left: 8%;
    }
}

.position-pull-up {
    position: relative;
    z-index: 10;
    top: -5rem;
}

.textbox {
    padding: 20px 10px;
    @include media-breakpoint-up(md){
        padding: 40px;
    }
}

.img-placeholder {
    display: block;
    width: 100%;
    height: 500px;
}

.img-placeholder--small {
    display: block;
    width: 100%;
    height: 350px;
}


.img-border-radius {
    border-radius: $border-radius-lg;
}

.img-gradient {
    position: relative;   
    img {
        position: relative;
        z-index: 1;
    } 
    &::before {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        bottom: -2rem;
        left: -1.5rem;
        background: linear-gradient(150deg, rgb(103, 197, 185), $primary);
        border-radius: $border-radius-lg;
        z-index: 0;
        opacity: 0.4;
    }
    &--right, &--left {
        &::before {
            width: 70%;
            height: 70%;
            padding-bottom: 0;
        }        
    }
    &--right {
        right: 2rem;
        &::before {
            left: auto;
            right: -1.5rem;
        }
    }
    &--left {
        left: 2rem;
        &::before {
            right: auto;
            left: -1.5rem;
        }
    }
}

.bg-num {
    position: absolute;
    left: 40px;
    top: -40px;
    z-index: 1;
    font-size: 90px;
    font-weight: 600;
    color: rgba($primary, 0.2);
    line-height: 1;
}

.img-30 {
    width: 310px;
}

.bg-white-opacity {
    background-color: rgba($white, 0.65);
}

.bg-blur-black {
    width: 100%;
    height: 100%;
    border-radius: 20%;
    position: absolute;
    background: $dark;
    filter: blur(60px);
    opacity: .5;
    z-index: -1;
}
.bg-blur-white {
    width: 100%;
    height: 100%;
    border-radius: 20%;
    position: absolute;
    background: $white;
    filter: blur(30px);
    opacity: .9;
    z-index: -1;
}

.bg-30 {
    background-image: url(../images/bg-30.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom right;
    padding: 2rem 2rem 10rem 2rem;
    @include media-breakpoint-up(lg){
        padding: 4rem 6rem 15rem 3rem;
    }
    @include media-breakpoint-up(sm){
        background-size: auto 100%;
    }
}

.bg-map {
    background-image: url(../images/bg-map.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
}

.bg-cta {
    background-image: url(../images/bg-cta-light-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-cta-light2.jpg);
    }
}

.bg-cta-megoldasok {
    background-image: url(../images/bg-informatikai-megoldasok-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-informatikai-megoldasok.jpg);
    }
}

.bg-callus {
    background-image: url(../images/bg-callus-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-callus.jpg);
    }
}

.bg-mobilapp {
    background-image: url(../images/bg-mobilapp2-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-mobilapp2.jpg);
    }
}

.bg-mobilapp-cta {
    background-image: url(../images/bg-mobilapp-dark-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-mobilapp-dark.jpg);
    }
}

.bg-downloads {
    background-image: url(../images/bg-downloads-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-downloads.jpg);
    }
}

.bg-iroda {
    background-color: transparent;
    background-image: linear-gradient(180deg,rgba(0, 0, 0, 0.001) 40%, rgba(0,0,10,0.1) 100%);
}

.bg-officeuk {
    background-image: url(../images/bg-iratkezeles-vallalatoknak-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-iratkezeles-vallalatoknak.jpg);
    }
}

.bg-officeukgov {
    background-image: url(../images/bg-iratkezeles-kozszferanak-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-iratkezeles-kozszferanak.jpg);
        @include background-fixed;
    }
}

.bg-officeukgov-2 {
    background-image: url(../images/bg-iratkezeles-kozszferanak2-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-iratkezeles-kozszferanak2.jpg);
        @include background-fixed;
    }
}


.bg-officeuk-video {
    background-image: url(../images/bg-officeuk-video-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-officeuk-video.jpg);
    }
}

.bg-officeuk-list {
    background-image: url(../images/bg-iratkezeles-office-sm.jpg);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% auto;
    padding-bottom: 110px !important;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-iratkezeles-office.jpg);
        padding-bottom: 210px !important;
    }  
    
}

.bg-hcldomino {
    background-image: url(../images/bg-hcl-domino-laptop-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-hcl-domino-laptop.jpg);
    }
}

.bg-rolunk {
    background-image: url(../images/bg-JonasGabor-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md){
        background-image: url(../images/bg-JonasGabor.jpg);
    }
}

.bg-hcl-licensz {
    background-image: url(../images/hcl-licensz.jpg);
    background-repeat: no-repeat;
    background-position: center;
}

.testimonial-box {
    &-text {
        background: $white;
        border-radius: $border-radius-lg;
        border: solid 1px $gray-light;
        position: relative;
        padding: 25px 20px;
        &:before {
            content: '';
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 15px solid $gray-light;
            position: absolute;
            bottom: -13px;
            left: 2rem;
            display: block;
        }
        &:after {
            content: '';
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 15px solid $white;
            position: absolute;
            bottom: -12px;
            left: 2rem;
            display: block;
        }
    }
}

.green-box {
    position: relative;
    padding: 40px 25px;
    background: $primary;
    color: $white;
    border-radius: $border-radius-lg;

    @media(max-width:991px){
        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            z-index: 1;
            border-style: solid;
            border-color: $primary transparent;
            border-width: 0 20px 20px;
            top: -20px;
            left: 50%;
            margin-left: -20px;
        }
    }

    @media(min-width:992px){
        &--left {
            
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 0;
                z-index: 1;
                border-style: solid;
                border-color: transparent $primary;
                border-width: 20px 20px 20px 0;
                top: 30%;
                left: -20px;
                margin-top: -20px;
            }
            
        }
        &--right {
            
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 0;
                z-index: 1;
                border-style: solid;
                border-color: transparent $primary;
                border-width: 20px 0 20px 20px;
                top: 30%;
                right: -20px;
                margin-top: -20px;
            }
            
        }
    }
    
    
}

.box-hover-white {
    @include transition-all;
    border-radius: $border-radius-lg;
    i {
        color: $white;
    }
    h4 {
        color: $white;
    }
    span, p {
        color: $white;
        margin-bottom: 0px;
    }
    &:hover {
        cursor: default;
        background-color: $white;
        @include shadow;
        i {
            color: $primary;
        }
        h4, span, p {
            color: $black;
        }
    }
}

.box-white {
    @include transition-all;
    border-radius: $border-radius-lg;
    background-color: $white;
    i {
        color: $primary;
    }
    h4 {
        color: $black;
    }
    span, p {
        color: $black;
        margin-bottom: 0px;
    }
}

.box-hover-green {
    @include transition-all;
    margin-bottom: 1rem;
    width: 150px;
    height: 150px;
    display: inline-block;
    //border: solid 1px $gray;
    padding: 1.5rem;
    border-radius: $border-radius-lg;
    h4 {
        font-size: 17px !important;
        font-weight: 600;
        color: $black;
    }
    i {
        color: $primary;
    }
    &:hover {
        cursor: default;
        background-color: $primary;
        @include shadow;
        i {
            color: $white;
        }
        h4 {
            color: $white;
        }
    }
}

.box-list-flex-icon {
    display: block;
    margin-bottom: 1rem;
    i {
        font-size: 40px;
        color: $primary;
        margin-right: 15px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.list-check {
    list-style: none;
    padding: 0;
    margin: 0 0 1rem 0;
    li {
        padding: 5px 10px 5px 30px;
        //border-bottom: solid 1px $gray;
        position: relative;
        background-image: url(../images/list-icon-check.svg);
        background-repeat: no-repeat;
        background-position: 0 5px;
        background-size: 25px 25px;
    }
}


.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -4rem !important;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
    li {
        border-radius: 50%;
        width: 17px;
        height: 17px;
        margin-right: 5px;
        margin-left: 5px;
        background-color: $primary;
        border-top: 0;
        border-bottom: 0;
    }
}

.footer-logo {
    height: 48px !important;
    width: auto !important;
    @include media-breakpoint-down(md){
        margin: 10px auto !important;
    }
}