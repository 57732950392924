
#contactForm {
  width: 100% !important;
  .success, .error {
      display: none;
      text-align: center;
      margin-bottom: 20px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 20px;
      background-color: rgba(255,255,255, 0.1);
  }
  .success {
      color: $green !important;
  }
  .error {
      color: $red !important;
  }
}

// keep a max width in case it is just the form and nothing else
// we do not want a form spanning whole page
.wpcf7 {
  max-width: 600px;
  margin: 0 auto !important;

  // all inputs except radios and checkboxes inherit from form-control
  input[type=text],
  input[type=search],
  input[type=url],
  input[type=tel],
  input[type=number],
  input[type=range],
  input[type=date],
  input[type=month],
  input[type=week],
  input[type=time],
  input[type=datetime],
  input[type=datetime-local],
  input[type=color],
  input[type=email],
  input[type=file],
  select,
  textarea {
    @extend .form-control;
    background-color: rgba(200,200,200,0.05);
    border: 0px;
    border-bottom: solid 1px $secondary;
  }

  // submit button, inherit .btn and .btn-outline-primary classes.
  input[type=submit] {
    @extend .btn;
    @extend .btn-secondary;
    border: 0px;
    width: 100%;
  }

  .wpcf7-form {

    // set paragraphs to behave like divs with class .form-group
    p {
      @extend .form-group;
    }

    // let labels be 100%
    label {
      width: 100%;
    }
  }

  // not valid tip for each control
  .wpcf7-not-valid-tip {
    color: theme-color("danger");
  }

  // validation errors ourput bottom of form
  .wpcf7-validation-errors {
    @extend .form-control;
    color: theme-color("danger");
    border: solid 1px $gray;
  }
}
