@mixin transition-all() {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

@mixin background-cover() {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;  
    background-repeat: no-repeat; 
    background-position: center;
}
@mixin background-contain() {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat; 
    background-position: bottom right;
}

@mixin background-fixed() {
    @include media-breakpoint-up(md) {
      background-attachment: fixed;
    }
}

@mixin background-pattern() {
    background-image: url(../images/pattern.png);
    background-repeat: no-repeat;
}

@mixin content-layer() {
    background-color: transparent;
    background-image: linear-gradient(180deg,rgba(0, 0, 10, 0.5) 0%, rgba(0,0,0,0.65) 100%);
}

@mixin theme-button() {
    @include transition-all;
    border-radius: $border-radius;
    padding: 12px 20px;
    //font-size: 0.875rem !important;
    //text-transform: uppercase;
    //font-weight: 600;
    text-decoration: none !important;
    border: solid 1px transparent;
    box-shadow: 0 3px 2px rgba(63,162,86,0.4);
    -webkit-box-shadow: 0 3px 2px rgba(63,162,86,0.4);
    line-height: 1.3;
    margin-bottom: 0.5rem;
}

@mixin shadow() {
    -webkit-box-shadow: 0 5px 40px -5px rgba(0,0,0,.1) !important;
    box-shadow: 0 5px 40px -5px rgba(0,0,0,.1) !important;
}
