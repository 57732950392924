
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Poppins-Light.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/Poppins-SemiBold.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/Poppins-Thin.woff') format('woff');
    font-display: swap;
}

@import "assets/bootstrap4";      // <-------- Loads Bootstrap4
@import "assets/font-awesome";    // <-------- Font Awesome Icon font
@import "assets/flaticon";    // <-------- flaticon

@import "theme/theme_variables";  // <-------- Add your variables into this file. Also add variables to overwrite Bootstrap variables here
@import "theme/mixins";            // <-------- theme mixins
@import "theme/theme";            // <-------- That's where you can add your own design. Thats your part!
@import "theme/buttons";            // <-------- buttons
@import "theme/fonts";            // <-------- buttons
@import "theme/navbar";            // <-------- navbar
@import "theme/contact-form";    // <-------- Contact Form - Bootstrap 4 support
@import "theme/magnificpopup";    // <-------- Magnific popup
@import "theme/loader";             // <-------- page loading
