.btn {
    @include theme-button;
}

.btn-icon {
    display: block;
    @include transition-all;    
    .fa {
        @include transition-all;
        background: transparent;        
        width: 38px;
        height: 38px;
        border-radius: 50%;
        font-size: 18px;
        padding: 10px;
        margin-right: 5px;        
    }
    span {
        font-size: 1rem;
        font-weight: 600;        
        padding: 5px;
    }

    &--primary {
        .fa {
            border: solid 1px $primary;
            color: $primary !important;
        }
        span {
            color: $primary !important;
        }
        &:hover {
            span {
                color: darken($primary, 10%);
            }
            .fa {
                background: $primary;
                color: $white !important;
            }
        }
    }

    &--white {
        .fa {
            border: solid 1px $white;
            color: $white !important;
        }
        span {
            color: $white !important;
        }
        &:hover {
            span {
                color: darken($white, 10%);
            }
            .fa {
                background: $white;
                color: $primary !important;
            }
        }
    }
    
    
}
