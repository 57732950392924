$flaticon-font: "flaticon";

@font-face {
    font-family: $flaticon-font;
    src: url("../fonts/flaticon.ttf?6268bda487e024b29652f9c2033af0ce") format("truetype"),
url("../fonts/flaticon.woff?6268bda487e024b29652f9c2033af0ce") format("woff"),
url("../fonts/flaticon.woff2?6268bda487e024b29652f9c2033af0ce") format("woff2"),
url("../fonts/flaticon.eot?6268bda487e024b29652f9c2033af0ce#iefix") format("embedded-opentype"),
url("../fonts/flaticon.svg?6268bda487e024b29652f9c2033af0ce#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$flaticon-map: (
    "research": "\f101",
    "healthcare": "\f102",
    "health": "\f103",
    "heartbeat": "\f104",
    "healthcare-1": "\f105",
    "caduceus": "\f106",
    "medicine": "\f107",
    "medicine-1": "\f108",
    "save-money": "\f109",
    "money": "\f10a",
    "money-bag": "\f10b",
    "bank": "\f10c",
    "transportation": "\f10d",
    "truck": "\f10e",
    "logistics": "\f10f",
    "logistics-1": "\f110",
    "delivery-truck": "\f111",
    "logistics-delivery": "\f112",
    "gear": "\f113",
    "water-drop": "\f114",
    "oil-industry": "\f115",
    "dippel-oil": "\f116",
    "jerrycan": "\f117",
    "oil-barrel": "\f118",
    "oil-barrel-1": "\f119",
    "compliant": "\f11a",
    "balance": "\f11b",
    "courthouse": "\f11c",
    "regulation": "\f11d",
    "courthouse-1": "\f11e",
    "mortarboard": "\f11f",
    "teaching": "\f120",
    "presentation": "\f121",
    "mortarboard-1": "\f122",
    "knowledge": "\f123",
    "customer-support": "\f124",
    "technical-support": "\f125",
    "maintenance": "\f126",
    "service": "\f127",
    "setting": "\f128",
    "microscope": "\f129",
    "laboratory": "\f12a",
    "microscope-1": "\f12b",
    "research-1": "\f12c",
    "website": "\f12d",
    "laptop": "\f12e",
    "laptop-1": "\f12f",
    "laptop-2": "\f130",
    "computer": "\f131",
    "cloud": "\f132",
    "shopping-bag": "\f133",
    "shop": "\f134",
    "online-shopping": "\f135",
    "shop-1": "\f136",
    "manufacture": "\f137",
    "factory": "\f138",
    "production": "\f139",
    "production-1": "\f13a",
    "radioactive": "\f13b",
    "nuclear-sign": "\f13c",
    "nuclear-energy": "\f13d",
    "nuclear": "\f13e",
    "atomic-energy": "\f13f",
    "radioactive-1": "\f140",
    "cheers": "\f141",
    "calendar": "\f142",
    "red-carpet": "\f143",
    "calendar-1": "\f144",
    "event": "\f145",
    "development": "\f146",
    "leader": "\f147",
    "united": "\f148",
    "meeting": "\f149",
    "idea": "\f14a",
    "team": "\f14b",
    "employee": "\f14c",
    "woman": "\f14d",
    "customer-service": "\f14e",
    "developer": "\f14f",
    "shield": "\f150",
    "secure-data": "\f151",
    "innovation": "\f152",
    "lightbulb": "\f153",
    "procedure": "\f154",
    "workflow": "\f155",
    "effect": "\f156",
    "rocket": "\f157",
    "rocket-ship": "\f158",
    "startup": "\f159",
    "rocket-1": "\f15a",
    "quality": "\f15b",
    "quality-1": "\f15c",
    "award": "\f15d",
);

.flaticon-research:before {
    content: map-get($flaticon-map, "research");
}
.flaticon-healthcare:before {
    content: map-get($flaticon-map, "healthcare");
}
.flaticon-health:before {
    content: map-get($flaticon-map, "health");
}
.flaticon-heartbeat:before {
    content: map-get($flaticon-map, "heartbeat");
}
.flaticon-healthcare-1:before {
    content: map-get($flaticon-map, "healthcare-1");
}
.flaticon-caduceus:before {
    content: map-get($flaticon-map, "caduceus");
}
.flaticon-medicine:before {
    content: map-get($flaticon-map, "medicine");
}
.flaticon-medicine-1:before {
    content: map-get($flaticon-map, "medicine-1");
}
.flaticon-save-money:before {
    content: map-get($flaticon-map, "save-money");
}
.flaticon-money:before {
    content: map-get($flaticon-map, "money");
}
.flaticon-money-bag:before {
    content: map-get($flaticon-map, "money-bag");
}
.flaticon-bank:before {
    content: map-get($flaticon-map, "bank");
}
.flaticon-transportation:before {
    content: map-get($flaticon-map, "transportation");
}
.flaticon-truck:before {
    content: map-get($flaticon-map, "truck");
}
.flaticon-logistics:before {
    content: map-get($flaticon-map, "logistics");
}
.flaticon-logistics-1:before {
    content: map-get($flaticon-map, "logistics-1");
}
.flaticon-delivery-truck:before {
    content: map-get($flaticon-map, "delivery-truck");
}
.flaticon-logistics-delivery:before {
    content: map-get($flaticon-map, "logistics-delivery");
}
.flaticon-gear:before {
    content: map-get($flaticon-map, "gear");
}
.flaticon-water-drop:before {
    content: map-get($flaticon-map, "water-drop");
}
.flaticon-oil-industry:before {
    content: map-get($flaticon-map, "oil-industry");
}
.flaticon-dippel-oil:before {
    content: map-get($flaticon-map, "dippel-oil");
}
.flaticon-jerrycan:before {
    content: map-get($flaticon-map, "jerrycan");
}
.flaticon-oil-barrel:before {
    content: map-get($flaticon-map, "oil-barrel");
}
.flaticon-oil-barrel-1:before {
    content: map-get($flaticon-map, "oil-barrel-1");
}
.flaticon-compliant:before {
    content: map-get($flaticon-map, "compliant");
}
.flaticon-balance:before {
    content: map-get($flaticon-map, "balance");
}
.flaticon-courthouse:before {
    content: map-get($flaticon-map, "courthouse");
}
.flaticon-regulation:before {
    content: map-get($flaticon-map, "regulation");
}
.flaticon-courthouse-1:before {
    content: map-get($flaticon-map, "courthouse-1");
}
.flaticon-mortarboard:before {
    content: map-get($flaticon-map, "mortarboard");
}
.flaticon-teaching:before {
    content: map-get($flaticon-map, "teaching");
}
.flaticon-presentation:before {
    content: map-get($flaticon-map, "presentation");
}
.flaticon-mortarboard-1:before {
    content: map-get($flaticon-map, "mortarboard-1");
}
.flaticon-knowledge:before {
    content: map-get($flaticon-map, "knowledge");
}
.flaticon-customer-support:before {
    content: map-get($flaticon-map, "customer-support");
}
.flaticon-technical-support:before {
    content: map-get($flaticon-map, "technical-support");
}
.flaticon-maintenance:before {
    content: map-get($flaticon-map, "maintenance");
}
.flaticon-service:before {
    content: map-get($flaticon-map, "service");
}
.flaticon-setting:before {
    content: map-get($flaticon-map, "setting");
}
.flaticon-microscope:before {
    content: map-get($flaticon-map, "microscope");
}
.flaticon-laboratory:before {
    content: map-get($flaticon-map, "laboratory");
}
.flaticon-microscope-1:before {
    content: map-get($flaticon-map, "microscope-1");
}
.flaticon-research-1:before {
    content: map-get($flaticon-map, "research-1");
}
.flaticon-website:before {
    content: map-get($flaticon-map, "website");
}
.flaticon-laptop:before {
    content: map-get($flaticon-map, "laptop");
}
.flaticon-laptop-1:before {
    content: map-get($flaticon-map, "laptop-1");
}
.flaticon-laptop-2:before {
    content: map-get($flaticon-map, "laptop-2");
}
.flaticon-computer:before {
    content: map-get($flaticon-map, "computer");
}
.flaticon-cloud:before {
    content: map-get($flaticon-map, "cloud");
}
.flaticon-shopping-bag:before {
    content: map-get($flaticon-map, "shopping-bag");
}
.flaticon-shop:before {
    content: map-get($flaticon-map, "shop");
}
.flaticon-online-shopping:before {
    content: map-get($flaticon-map, "online-shopping");
}
.flaticon-shop-1:before {
    content: map-get($flaticon-map, "shop-1");
}
.flaticon-manufacture:before {
    content: map-get($flaticon-map, "manufacture");
}
.flaticon-factory:before {
    content: map-get($flaticon-map, "factory");
}
.flaticon-production:before {
    content: map-get($flaticon-map, "production");
}
.flaticon-production-1:before {
    content: map-get($flaticon-map, "production-1");
}
.flaticon-radioactive:before {
    content: map-get($flaticon-map, "radioactive");
}
.flaticon-nuclear-sign:before {
    content: map-get($flaticon-map, "nuclear-sign");
}
.flaticon-nuclear-energy:before {
    content: map-get($flaticon-map, "nuclear-energy");
}
.flaticon-nuclear:before {
    content: map-get($flaticon-map, "nuclear");
}
.flaticon-atomic-energy:before {
    content: map-get($flaticon-map, "atomic-energy");
}
.flaticon-radioactive-1:before {
    content: map-get($flaticon-map, "radioactive-1");
}
.flaticon-cheers:before {
    content: map-get($flaticon-map, "cheers");
}
.flaticon-calendar:before {
    content: map-get($flaticon-map, "calendar");
}
.flaticon-red-carpet:before {
    content: map-get($flaticon-map, "red-carpet");
}
.flaticon-calendar-1:before {
    content: map-get($flaticon-map, "calendar-1");
}
.flaticon-event:before {
    content: map-get($flaticon-map, "event");
}
.flaticon-development:before {
    content: map-get($flaticon-map, "development");
}
.flaticon-leader:before {
    content: map-get($flaticon-map, "leader");
}
.flaticon-united:before {
    content: map-get($flaticon-map, "united");
}
.flaticon-meeting:before {
    content: map-get($flaticon-map, "meeting");
}
.flaticon-idea:before {
    content: map-get($flaticon-map, "idea");
}
.flaticon-team:before {
    content: map-get($flaticon-map, "team");
}
.flaticon-employee:before {
    content: map-get($flaticon-map, "employee");
}
.flaticon-woman:before {
    content: map-get($flaticon-map, "woman");
}
.flaticon-customer-service:before {
    content: map-get($flaticon-map, "customer-service");
}
.flaticon-developer:before {
    content: map-get($flaticon-map, "developer");
}
.flaticon-shield:before {
    content: map-get($flaticon-map, "shield");
}
.flaticon-secure-data:before {
    content: map-get($flaticon-map, "secure-data");
}
.flaticon-innovation:before {
    content: map-get($flaticon-map, "innovation");
}
.flaticon-lightbulb:before {
    content: map-get($flaticon-map, "lightbulb");
}
.flaticon-procedure:before {
    content: map-get($flaticon-map, "procedure");
}
.flaticon-workflow:before {
    content: map-get($flaticon-map, "workflow");
}
.flaticon-effect:before {
    content: map-get($flaticon-map, "effect");
}
.flaticon-rocket:before {
    content: map-get($flaticon-map, "rocket");
}
.flaticon-rocket-ship:before {
    content: map-get($flaticon-map, "rocket-ship");
}
.flaticon-startup:before {
    content: map-get($flaticon-map, "startup");
}
.flaticon-rocket-1:before {
    content: map-get($flaticon-map, "rocket-1");
}
.flaticon-quality:before {
    content: map-get($flaticon-map, "quality");
}
.flaticon-quality-1:before {
    content: map-get($flaticon-map, "quality-1");
}
.flaticon-award:before {
    content: map-get($flaticon-map, "award");
}
