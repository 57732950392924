.navbar {
    &.navbar-shrink {
        background: rgba($white, 0.9) !important;
    }
    .brand-logo {
        img {
            display: block;            
            height: auto;
            max-width: 170px;
            @include media-breakpoint-up(sm){
                max-width: 210px;
            }
        }
        
    }

    .nav-item {
        .nav-link {
            font-size: 90% !important;
            color: $gray-500 !important;
            font-weight: 600 !important;
        }
    }
}

// overlay menus

.overlay {
    .dropdown-menu, .sub-menu {
        display: none;
    }
    height: 100%;
    width: 0%;
    position: fixed;
    z-index: 1000;
    top: 70px;
    left: 0;
    background-color: $dark;
    overflow-x: hidden;
    transition: 0.5s;
    
    &-closebtn {
        position: absolute;
        top: $body-padding-top * 0.7;
        right: 45px;
        font-size: calc(1.4rem + 5vmin);
        color: $white !important;
        text-decoration: none !important;
    }

    &-content {
        position: relative;
        top: 8%;
        left: 10%;
        width: 100%;
        text-align: left;
        margin-top: $body-padding-top;
        font-family: $heading-font;
        font-size: 1rem;
    
        .nav-item {
        a {
            font-family: $heading-font;            
            text-decoration: none;
            color: $white;
            display: block;
            transition: 0.3s;
            &:hover, &:focus {
                color: $light !important;
            }
            }
        }
    }
  }
  
    
@media screen and (max-width: 500px) {
.overlay {
    overflow-y: auto;
    a {
        padding: 5px;
    }
    &-closebtn {
        right: 20px;
    }
}

}